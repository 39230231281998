import { isRouteErrorResponse, useRouteError } from 'react-router';

const ErrorBoundary = () => {
    let error = useRouteError() as Error;

    if (window.DD_RUM && window.DD_RUM.addError) {
        window.DD_RUM.addError(error);
    }
    if (isRouteErrorResponse(error)) {
        return (
            <>
                <div>{error.status}</div>
                <div>{error?.data}</div>
            </>
        );
    }

    return (
        <p role="alert" className="p-4">
            Oops ! Sorry, we encountered a technical problem. Please reload the page or try again later.
        </p>
    );
};

export default ErrorBoundary;
